import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  width: "16",
  height: "16",
  fill: "none",
  viewBox: "0 0 16 16"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createStaticVNode("<rect width=\"16\" height=\"16\" fill=\"#A5B4FC\" rx=\"4\"></rect><g stroke=\"#000\" stroke-linecap=\"round\" stroke-linejoin=\"round\" clip-path=\"url(#1847842f__a)\"><path d=\"M7.252 4.998H4.335c-.736 0-1.333.597-1.333 1.334v4.333c0 .737.597 1.334 1.333 1.334h7.334c.737 0 1.334-.597 1.334-1.334V8.25\"></path><path d=\"M11.58 3.742a.833.833 0 0 1 1.179 1.179L9.217 8.462a.8.8 0 0 1-.335.199l-1.128.33a.196.196 0 0 1-.244-.244l.33-1.129a.8.8 0 0 1 .199-.334z\"></path></g><defs><clipPath id=\"1847842f__a\"><path fill=\"#fff\" d=\"M2 1.998h12v12H2z\"></path></clipPath></defs>", 3)
  ])))
}
export default { render: render }