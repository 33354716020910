<script setup lang="ts">
import SimpleButton from "ls/components/simple-button/SimpleButton.vue";
import { SimpleDialog, SimpleDialogContent, SimpleDialogDescription, SimpleDialogFooter, SimpleDialogHeader, SimpleDialogTitle } from "ls/components/simple-dialog";
import { toOrganizationMembers } from "ls/router/builders";
import { useSubInstanceId } from "ls/state/useSubInstance";
import { DialogClose } from "reka-ui";
import { computed } from "vue";
import { useOrganization } from "../organizations/useOrganization";
import { useAccessLevelFromOrganization } from "../organizations/useOrganizationAccessLevel";
import { useAxureRpRoleFromOrganization } from "../organizations/useOrganizationAxureRpRole";
import { useRpAccessControl } from "./useRpAccessControl";

const { clear, errorType } = useRpAccessControl();

const subInstanceId = useSubInstanceId();
const organization = useOrganization(() => subInstanceId.value);
const { isAdmin } = useAccessLevelFromOrganization(organization);
const { isPublisher } = useAxureRpRoleFromOrganization(organization);

const isOpen = computed(() => errorType.value === "subInstanceLicenseAudit" || errorType.value === "noPermission");

const description = computed(() => {
  if (isAdmin.value) {
    return isPublisher.value
      ? "If you have Axure RP Enterprise, please publish from Axure RP to verify your license. If not, please assign an Axure RP Publisher seat to your account on the Members page."
      : "You can change your Axure RP role to Publisher on the Members page so you can publish RP Prototypes.";
  } else {
    return isPublisher.value
      ? "If you have Axure RP Enterprise, please publish from Axure RP to verify your license. If not, please ask an admin to assign an Axure RP Publisher seat to your account."
      : "Please ask an admin to change your Axure RP role to Publisher so you can publish RP Prototypes.";
  }
});

function handleDialogOpen(open: boolean) {
  if (!open) clear();
}
</script>

<template>
  <SimpleDialog
    v-if="isOpen"
    :open="isOpen"
    @update:open="handleDialogOpen"
  >
    <SimpleDialogContent>
      <SimpleDialogHeader>
        <SimpleDialogTitle>
          You need {{ errorType === 'subInstanceLicenseAudit' ? "a license" : "permission" }} to publish RP Prototypes
        </SimpleDialogTitle>
        <SimpleDialogDescription> {{ description }} </SimpleDialogDescription>
      </SimpleDialogHeader>
      <SimpleDialogFooter v-if="isAdmin && organization">
        <DialogClose>
          <SimpleButton
            :href="toOrganizationMembers(organization.Id)"
            size="large"
          >
            Go to members page
          </SimpleButton>
        </DialogClose>
      </SimpleDialogFooter>
    </SimpleDialogContent>
  </SimpleDialog>
</template>
