import type { DatabaseConnection, DatabaseMode } from "./utils";
import { useAxureCloudConfig } from "ls/state/useAxureCloudConfig";
import { computed, ref } from "vue";

export function useIsDbUpgrade() {
  const config = useAxureCloudConfig();
  return computed(() => config.value?.NeedsDbUpgrade ?? false);
}

const connection = ref<DatabaseConnection>();
const databaseMode = ref<DatabaseMode>();
const connectionErrorMessage = ref<string>();

export function useOnPremSetupContext() {
  return {
    connection,
    databaseMode,
    connectionErrorMessage,
  };
}
