<script setup lang="ts">
import { useLocalStorage } from "@vueuse/core";
import SimpleButton from "ls/components/simple-button/SimpleButton.vue";
import { useAxureCloudConfig } from "ls/state/useAxureCloudConfig";
import { computed } from "vue";
import SimpleBanner from "./SimpleBanner.vue";

const config = useAxureCloudConfig();
const managePageMessage = computed(() => config?.value?.ManagePageMessage);

const dismissed = useLocalStorage<string>("dismissed-maintenance-message", "");

const isDismissed = computed(() => {
  return dismissed.value === managePageMessage.value;
});

function dismiss() {
  dismissed.value = managePageMessage.value;
}

const showMaintenanceBanner = computed(() => {
  if (isDismissed.value) return false;
  return !!managePageMessage.value;
});
</script>

<template>
  <SimpleBanner v-if="showMaintenanceBanner">
    <template #default>
      {{ managePageMessage }}
    </template>
    <template #controls>
      <SimpleButton class="ml-auto" @click="dismiss">
        Got it
      </SimpleButton>
    </template>
  </SimpleBanner>
</template>
