<script setup lang="ts">
import { useIsSubInstance } from "ls/state/useSubInstance";
import ReadOnlyBanner from "./ReadOnlyBanner.vue";
import MaintenanceBanner from "./MaintenanceBanner.vue"
import SubinstanceBanner from "./SubinstanceBanner.vue";

const isSubInstance = useIsSubInstance();
</script>

<template>
  <div class="divide-y divide-zinc-600">
    <ReadOnlyBanner />
    <MaintenanceBanner />
    <SubinstanceBanner v-if="isSubInstance" />
  </div>
</template>
