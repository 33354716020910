import type { PrototypeViewerParams, ResponseObject, ShortcutCloneJs, ShortcutCreateJs, ShortcutJs, ShortcutPagesResponse, ShortcutRedirectCommentInfoJs, VarsJs } from "@/generated/models";
import type { RequestOptions } from "../common/server";
import type { IdOrFilesystemId } from "./types";
import { asError } from "@/common/lib";
import { objectToFormData } from "@/services/utils/formData";
import { isCloudResponse } from "../common/server";
import { server } from "./server";

export async function getPrototypeInfo(shortcut: string, { signal }: RequestOptions = {}) {
  try {
    const response = await server.get(`prototype/getPrototypeInfo/${shortcut}`, { signal });
    return response.data as ShortcutJs;
  } catch (e) {
    // getPrototypeInfo may return redirect to a different page to request workspaces access
    const error = asError(e);
    const response = error.cause;
    if (isCloudResponse(response) && response.redirecturl) {
      window.location.href = response.redirecturl;
    }

    throw e;
  }
}

export async function getPrototypePages(shortcut: string, { signal }: RequestOptions = {}) {
  const response = await server.get(`prototype/pages/${shortcut}`, { signal });
  return response.data as ShortcutPagesResponse;
}

export function setFeedback(shortcut: string, feedbackEnabled: boolean) {
  const formData = objectToFormData({ feedbackEnabled });
  return server.post(`prototype/setFeedback/${shortcut}`, formData);
}

export async function getViewerParams({ signal }: RequestOptions = {}) {
  const response = await server.get(`prototype/getViewerParams`, { signal });
  return response.data as PrototypeViewerParams;
}

export interface ChangeProjectInfo {
  Name: string;
  Password: string;
  fileData: File | null;
  IsUserOnly: boolean | null;
  PublishNote: string | null;
  PublishNoteRichText: string | null;
}
export async function changePrototypeInfo(
  shortcut: string,
  changedProjectInfo: Partial<ChangeProjectInfo>,
  options: RequestOptions = {},
) {
  const formData = objectToFormData(changedProjectInfo);
  const response = await server.post(`prototype/changePrototypeInfo/${shortcut}`, formData, {
    signal: options.signal,
    onUploadProgress: options.onUploadProgress,
  });

  return response.data as ShortcutJs;
}

export async function uploadPrototypeFile(
  shortcut: string,
  changedProjectInfo: Partial<ChangeProjectInfo>,
  options: RequestOptions = {},
) {
  const formData = objectToFormData(changedProjectInfo);
  const response = await server.post(`prototype/uploadPrototypeFile/${shortcut}`, formData, {
    signal: options.signal,
    onUploadProgress: options.onUploadProgress,
  });

  return response.data as ShortcutJs;
}

export async function getPassword(shortcut: string, { signal }: RequestOptions = {}) {
  const response = await server.get(`prototype/getPassword/${shortcut}`, { signal });
  return response.data as VarsJs & { Vars: { password: string } };
}

export type CreateEmptyModel = IdOrFilesystemId & { Name?: string };

export async function createEmpty(model: CreateEmptyModel) {
  const formData = objectToFormData(model);
  const response = await server.post("prototype/createEmpty", formData);
  return response.data as ShortcutCreateJs;
}

export async function getPrototypeRedirectCommentInfo(shortcut: string) {
  const response = await server.get(`prototype/getPrototypeRedirectCommentInfo/${shortcut}`);
  return response.data as ShortcutRedirectCommentInfoJs;
}

export async function cloneShortcut({ shortcut, recaptchaToken }: { shortcut: string; recaptchaToken?: string }) {
  const formData = objectToFormData({ shortcut, recaptchaToken });
  const response = await server.post("prototype/cloneShortcut", formData);
  return response.data as ShortcutCloneJs;
}

export async function createPublishNote(shortcut: string, publishNote: string) {
  const formData = objectToFormData({ PublishNoteRichText: publishNote });
  const response = await server.post(`prototype/createPublishNote/${shortcut}`, formData);
  return response.data as ResponseObject;
}

export type CanUploadOptions = IdOrFilesystemId & {
  isCreate: boolean;
}

export async function canUpload(options: CanUploadOptions, { signal }: RequestOptions = {}) {
  const response = await server.get("prototype/canUpload", {
    params: options,
    signal,
  });

  return response.data as ResponseObject;
}

export async function resetUnreadPublishNotesMentions(shortcut: string) {
  const formData = objectToFormData({ shortcut });
  const response = await server.post("prototype/resetUnreadPublishNotesMentions", formData);
  return response.data as ResponseObject;
}
