<script setup lang="ts">
import { ShortcutType } from "@/generated/models";
import SimpleButton from "ls/components/simple-button/SimpleButton.vue";
import { SimpleDialog, SimpleDialogContent, SimpleDialogDescription, SimpleDialogFooter, SimpleDialogHeader, SimpleDialogTitle } from "ls/components/simple-dialog";
import { toOrganizationMembers } from "ls/router/builders";
import { DialogClose } from "reka-ui";
import { computed } from "vue";
import { useOnlineProjectActionGuard } from "../files/useOnlineProjectActionGuard";
import { useAccessLevelFromOrganization } from "../organizations/useOrganizationAccessLevel";

const { state, resetState } = useOnlineProjectActionGuard();

const projectTypeText = computed(() => {
  if (state.value.status === "idle") return "";
  if (state.value.type === ShortcutType.Whiteboard) return "whiteboards";
  if (state.value.type === ShortcutType.Document) return "documents";
  return "";
});

const organization = computed(() => {
  if (state.value.status === "idle") return undefined;
  return state.value.organization;
});

const { isAdmin } = useAccessLevelFromOrganization(organization);

function handleDialogOpen(open: boolean) {
  if (!open) resetState();
}
</script>

<template>
  <SimpleDialog
    v-if="state.status === 'forbidden'"
    :open="state.status === 'forbidden'"
    @update:open="handleDialogOpen"
  >
    <SimpleDialogContent>
      <SimpleDialogHeader>
        <SimpleDialogTitle>
          <template v-if="state.action && projectTypeText">
            You need permission to {{ state.action }} {{ projectTypeText }}
          </template>
          <template v-else>
            You do not have permissions to perform this action
          </template>
        </SimpleDialogTitle>
        <SimpleDialogDescription>
          <template v-if="isAdmin">
            You can change your Cloud role to Author on the Members page so you can create documents and whiteboards.
          </template>
          <template v-else-if="state.action && projectTypeText">
            Please ask an admin to change your Cloud role to Author so you can {{ state.action }} {{ projectTypeText }}.
          </template>
          <template v-else>
            Please ask an admin to change your Cloud role to Author.
          </template>
        </SimpleDialogDescription>
      </SimpleDialogHeader>
      <SimpleDialogFooter v-if="isAdmin && organization">
        <DialogClose>
          <SimpleButton
            :href="toOrganizationMembers(organization.Id)"
            size="large"
          >
            Go to members page
          </SimpleButton>
        </DialogClose>
      </SimpleDialogFooter>
    </SimpleDialogContent>
  </SimpleDialog>
</template>
