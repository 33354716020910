import type { AccountRowJs, FilesystemJs, FolderRowJs, RecentJs, ShortcutJs } from "@/generated/models";
import * as v from "valibot";

// @ts-expect-error avoid full schema declaration
export const ShortcutJsSchema = v.object({
  Shortcut: v.string(),
  Name: v.string(),
}) as v.GenericSchema<ShortcutJs>;
export const isShortcutJs = (input: unknown) => v.is(ShortcutJsSchema, input);

// @ts-expect-error avoid full schema declaration
export const FolderRowJsSchema = v.object({
  FolderId: v.string(),
  FolderName: v.string(),
}) as v.GenericSchema<FolderRowJs>;
export const isFolderRowJs = (input: unknown) => v.is(FolderRowJsSchema, input);

// @ts-expect-error avoid full schema declaration
export const FilesystemJsSchema = v.object({
  FilesystemId: v.string(),
  DisplayName: v.string(),
}) as v.GenericSchema<FilesystemJs>;
export const isFilesystemJs = (input: unknown) => v.is(FilesystemJsSchema, input);

export const OrganizationJsSchema = v.object({
  Id: v.string(),
  Name: v.string(),
  CurrentUserAccessLevel: v.number(),
  CurrentUserCloudRole: v.number(),
  CurrentUserAxureRpRole: v.number(),
});
export const isOrganizationJs = (input: unknown) => v.is(OrganizationJsSchema, input);

// @ts-expect-error avoid full schema declaration
export const AccountRowJsSchema = v.object({
  Id: v.string(),
  Email: v.string(),
}) as v.GenericSchema<AccountRowJs>;
export const isAccountRowJs = (input: unknown) => v.is(AccountRowJsSchema, input);

// @ts-expect-error avoid full schema declaration
export const RecentJsSchema = v.object({
  Shortcut: ShortcutJsSchema,
  Path: v.string(),
}) as v.GenericSchema<RecentJs>;
export const isRecentJs = (input: unknown) => v.is(RecentJsSchema, input);

export const EntitySchemas = v.union([OrganizationJsSchema, FilesystemJsSchema, FolderRowJsSchema, ShortcutJsSchema]);
export const isEntityJs = (input: unknown) => v.is(EntitySchemas, input);
