import type { OrganizationJs } from "@/generated/models";
import type { DiskoType } from "./utils";
import { EmptyGuid } from "@/common/lib";
import { UserCloudRole } from "@/generated/models";
import { useQueryClient } from "@tanstack/vue-query";
import { createSharedComposable } from "@vueuse/core";
import { readonly, ref } from "vue";
import { queries as organizationQueries } from "../organizations/queries";
import { isDiskoProjectType } from "./utils";

type Action = "create" | "duplicate";

type State =
  { status: "idle" } |
  { status: "allowed"; action: Action; type: DiskoType; organization?: OrganizationJs } |
  { status: "forbidden"; action: Action; type: DiskoType; organization?: OrganizationJs };

function useOnlineProjectActionGuardFn() {
  const queryClient = useQueryClient();

  const state = ref<State>({ status: "idle" });

  function resetState() {
    state.value = { status: "idle" };
  }

  async function challenge(organizationId: string, action: Action, type: DiskoType) {
    if (isDiskoProjectType(type) && organizationId === EmptyGuid) {
      return { status: "allowed" } as const;
    }

    try {
      const organization = await queryClient.ensureQueryData(organizationQueries.info(organizationId));
      const allowed = organization.CurrentUserCloudRole === UserCloudRole.Author;
      return { status: allowed ? "allowed" : "forbidden", organization } as const;
    } catch {
      return { status: "forbidden" } as const;
    }
  }

  async function checkPermission(organizationId: string | undefined, action: Action, type: DiskoType) {
    resetState();

    if (!organizationId) {
      state.value = { status: "forbidden", action, type };
      return false;
    }

    const newState = await challenge(organizationId, action, type);
    state.value = { status: newState.status, organization: newState.organization, action, type };

    return newState.status === "allowed";
  }

  return {
    state: readonly(state),
    checkPermission,
    resetState,
  };
}

export const useOnlineProjectActionGuard = createSharedComposable(useOnlineProjectActionGuardFn);
