import { createInjectionState } from "@vueuse/core";
import { useContextOrThrow } from "ls/composables/useContextOrThrow";
import { useId } from "reka-ui";

const [useProvideFormContext, useFormContextFn] = createInjectionState(() => {
  const formId = useId();
  return {
    formId,
    formLabelId: `${formId}-form-label`,
  };
});

export { useProvideFormContext };
export const useFormContext = useContextOrThrow(useFormContextFn);
