import type { RefOrGetter } from "ls/common/types";
import { createTwoWayMappers } from "@/common/lib/object";
import { CloudSubscriptionTerm, SubInstanceLevel } from "@/generated/models";
import { useQuery } from "@tanstack/vue-query";
import { computed, toValue } from "vue";
import { useAuth } from "../user/useAuth";
import { queries } from "./queries";
import { useOrganizationAdminInfo } from "./useOrganizationAdminInfo";

export function useSubInstanceLicensingInfo(organizationId: RefOrGetter<string>) {
  const subInstanceAdminInfo = useOrganizationAdminInfo(organizationId);
  const { user } = useAuth();
  const hasSubscription = computed(() => subInstanceAdminInfo.value && subInstanceAdminInfo.value.Level === SubInstanceLevel.Subscribed && subInstanceAdminInfo.value.MaxAdminAuthors > 0);

  const isCurrentUserSubInstanceOwner = computed(() => {
    if (!subInstanceAdminInfo.value) return false;

    if (!user.value) return false;

    return subInstanceAdminInfo.value.OwnerEmail === user.value.userEmail;
  });

  const isExpired = computed(() => {
    if (!subInstanceAdminInfo.value) return false;

    return subInstanceAdminInfo.value.IsExpired;
  });

  const isInGracePeriod = computed(() => {
    if (!subInstanceAdminInfo.value) return false;

    return subInstanceAdminInfo.value.IsGracePeriod;
  });

  const gracePeriodDaysLeft = computed(() => {
    if (!subInstanceAdminInfo.value) return -1;

    return subInstanceAdminInfo.value.GracePeriodDaysLeft;
  });

  const ownerEmail = computed(() => {
    if (!subInstanceAdminInfo.value) return "";

    return subInstanceAdminInfo.value.OwnerEmail;
  });

  const maxAdminAuthors = computed(() => {
    if (!subInstanceAdminInfo.value) return 0;

    return subInstanceAdminInfo.value.MaxAdminAuthors;
  });

  const seatUsersCount = computed(() => {
    if (!subInstanceAdminInfo.value) return 0;

    return subInstanceAdminInfo.value.SeatUsersCount;
  });

  return {
    hasSubscription,
    isExpired,
    isInGracePeriod,
    gracePeriodDaysLeft,
    isCurrentUserSubInstanceOwner,
    ownerEmail,
    maxAdminAuthors,
    seatUsersCount,
  };
}

export function useSubInstanceSubscriptionsInfo(organizationId: RefOrGetter<string>) {
  const query = useQuery(computed(() => queries.subscriptionsInfo(toValue(organizationId))));
  return query.data;
}

export const cloudSubscriptionTermMap = {
  [CloudSubscriptionTerm.Annual]: "Annual",
  [CloudSubscriptionTerm.Manual]: "Manual",
  [CloudSubscriptionTerm.Monthly]: "Monthly",
  [CloudSubscriptionTerm.Perpetual]: "Perpetual",
  [CloudSubscriptionTerm.Year2]: "Year2",
  [CloudSubscriptionTerm.Year3]: "Year3",
} as const;

export const [toCloudSubscriptionTerm, fromCloudSubscriptionTerm] = createTwoWayMappers(cloudSubscriptionTermMap);
