<script setup lang="ts">
import type { ComboboxItemEmits, ComboboxItemProps } from "reka-ui";
import { ComboboxItem, useForwardPropsEmits } from "reka-ui";

export type CommandItemProps = ComboboxItemProps;

const props = defineProps<ComboboxItemProps>();
const emits = defineEmits<ComboboxItemEmits>();

const forwarded = useForwardPropsEmits(props, emits);
</script>

<template>
  <ComboboxItem
    v-bind="forwarded"
    class="relative flex flex-row flex-nowrap items-center gap-2 whitespace-nowrap rounded-sm outline-hidden data-highlighted:bg-gray-200/40 data-disabled:text-slate-500"
  >
    <slot />
  </ComboboxItem>
</template>
