import type { ResponseObject } from "@/generated/models";
import type { ResponseObjectOf } from "@/services/models/responseObject";
import type { AxiosInstance, AxiosProgressEvent, AxiosResponse } from "axios";
import { isBoolean, isNumber, isObject, isString } from "@/common/general";
import { isAxiosError } from "axios";

export interface RequestOptions {
  signal?: AbortSignal;
  onUploadProgress?: (progressEvent: AxiosProgressEvent) => void;
}

export interface ProblemDetailsException {
  details: string;
  endpoint: string;
  headers: Record<string, string[]>;
  path: string;
}

export interface ProblemDetails {
  detail?: string;
  exception?: ProblemDetailsException;
  instance?: string;
  status: number;
  title: string;
  type?: string;
}

export function isProblemDetails(value: unknown): value is ProblemDetails {
  if (!isObject(value)) return false;
  return isNumber(value.status) && isString(value.title);
}

function isCloudError(value: ResponseObject) {
  if (!isObject(value)) return false;
  if (!isBoolean(value.success)) return false;
  return !value.success;
}

export function isCloudResponse(value: unknown): value is ResponseObject {
  return isObject(value) && isBoolean(value.success);
}

function isCloudDataResponse(value: ResponseObject): value is ResponseObjectOf {
  return "data" in value;
}

export function setupServer(server: AxiosInstance) {
  server.interceptors.response.use(miniProfilerResponseInterceptor);

  server.interceptors.response.use(response => {
    if (!isCloudResponse(response.data)) return response;

    if (isCloudError(response.data)) {
      throw new Error(response.data.errorMessage ?? response.data.message ?? undefined, { cause: response.data });
    }

    if (isCloudDataResponse(response.data)) {
      response.data = response.data.data;
    }

    return response;
  }, error => {
    if (isAxiosError(error)) {
      if (error.response && isProblemDetails(error.response.data)) {
        const problem = error.response.data;
        if (problem.exception) {
          throw new Error(problem.exception.details);
        }
        const message = problem.detail ?? problem.title;
        throw new Error(message);
      }
    }
    return Promise.reject(error);
  });
}

export function miniProfilerResponseInterceptor(response: AxiosResponse) {
  const miniProfiler = window.MiniProfiler;
  if (!miniProfiler || typeof miniProfiler.fetchResults !== "function") return response;

  let miniProfilerIdsHeader = "";
  for (const key in response.headers) {
    if (Object.prototype.hasOwnProperty.call(response.headers, key)) {
      if (key.toLowerCase() === "x-miniprofiler-ids") {
        miniProfilerIdsHeader = response.headers[key];
      }
    }
  }

  if (miniProfilerIdsHeader) {
    const profilerIds = JSON.parse(miniProfilerIdsHeader) as string[] | undefined;
    if (Array.isArray(profilerIds) && profilerIds.length) {
      miniProfiler.fetchResults(profilerIds);
    }
  }

  return response;
}
