import type { OrganizationJs } from "@/generated/models";
import type { RefOrGetter } from "ls/common/types";
import { UserAxureRpRole } from "@/generated/models";
import { computed, toValue } from "vue";
import { useOrganization } from "./useOrganization";

export function useOrganizationAxureRpRole(organizationId: RefOrGetter<string>) {
  const organization = useOrganization(organizationId);
  return useAxureRpRoleFromOrganization(organization);
}

export function useAxureRpRoleFromOrganization(organization: RefOrGetter<OrganizationJs | undefined>) {
  const axureRpRole = computed(() => toValue(organization)?.CurrentUserAxureRpRole);

  const isPublisher = computed(() => axureRpRole.value === UserAxureRpRole.Publisher);
  const isViewer = computed(() => axureRpRole.value === UserAxureRpRole.Viewer);

  return {
    isPublisher,
    isViewer,
  };
}
