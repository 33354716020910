<script setup lang="ts">
import type { DesktopNotificationOptions } from "ls/features/settings/useDesktopNotifications";
import { MentionType } from "@/common/fs/types";
import { equalsIgnoringCase } from "@/common/lib";
import { useQueryClient } from "@tanstack/vue-query";
import { getVisitorNotifications } from "ls/api/cloud/notification";
import { getPrototypeInfo } from "ls/api/cloud/prototype";
import { getFileLinks } from "ls/features/files/useFileLinks";
import { useAxureCloudNotificationsHub } from "ls/features/hubs/useAxureCloudNotificationsHub";
import { useDesktopNotifications } from "ls/features/settings/useDesktopNotifications";
import { toFileHistory, toFileOverview, toFilePreview, toManage } from "ls/router/builders";
import { issueCodeParameterName, workspaceParameterName } from "ls/router/constants";
import { useAxureCloudConfig } from "ls/state/useAxureCloudConfig";
import { useRoute, useRouter } from "vue-router";
import { drawBadgeAsBase64 } from "../notifications/badgeDrawer";
import { queries as organizationQueries } from "../organizations/queries";
import { getAvatarBackgroundColor, getUserInitials, getUserName } from "../user/utils";
import { queries as workspaceQueries } from "../workspaces/queries";

const {
  userMentioned,
  workspaceInvited,
  workspaceInvitationDeleted,
} = useAxureCloudNotificationsHub();

const config = useAxureCloudConfig();
const router = useRouter();

function drawUserAvatar(displayName: string) {
  return drawBadgeAsBase64({
    text: getUserInitials(displayName),
    backgroundColor: getAvatarBackgroundColor(displayName),
    font: "40px Inter,sans-serif",
    radius: 40,
    textOffsetTop: 5,
  });
}

const { show } = useDesktopNotifications();
userMentioned.on(async payload => {
  if (!config.value) return;
  const displayName = getUserName(payload.AuthorEmail, payload.AuthorName);
  const options: DesktopNotificationOptions = {
    title: `New message from ${displayName}`,
    body: payload.Message,
    tag: payload.Id,
    iconUrl: payload.AuthorProfileImage ?? drawUserAvatar(displayName),
    onclick: async () => {
      if (payload.MentionType === MentionType.Issue || payload.MentionType === MentionType.IssueComment) {
        // open the public link instead of the cloud preview page for shortcuts with visitor subscription
        const subscriptions = await getVisitorNotifications();
        if (subscriptions.Items.some(x => equalsIgnoringCase(x.ShortcutKey, payload.Shortcut))) {
          const file = await getPrototypeInfo(payload.Shortcut);
          const urls = getFileLinks(file, config.value);
          window.location.href = `${urls.full}#${issueCodeParameterName}=${payload.SourceId}&type=issue`;
          return;
        }
        await router.push(toFilePreview(payload.Shortcut, payload.PageId, { comment: payload.SourceId }));
      } else if (payload.MentionType === MentionType.PublishNote) {
        await router.push(toFileHistory(payload.Shortcut));
      } else if (payload.MentionType === MentionType.DiskoDocumentNode) {
        await router.push(toFileOverview(payload.Shortcut, { node: payload.SourceId }));
      } else if (payload.MentionType === MentionType.DiskoDiagramObject) {
        await router.push(toFileOverview(payload.Shortcut, { object: payload.SourceId }));
      }
    },
  };

  show(options);
});

const queryClient = useQueryClient();
workspaceInvited.on(payload => {
  void queryClient.invalidateQueries({ queryKey: workspaceQueries.invitations() });
  void queryClient.invalidateQueries({ queryKey: workspaceQueries.workspaces() });
  void queryClient.invalidateQueries({ queryKey: organizationQueries.organizations() });

  const displayName = getUserName(payload.OwnerEmail, payload.OwnerName);
  const options: DesktopNotificationOptions = {
    title: `New workspace invitation from ${displayName}`,
    body: payload.MentionComment,
    tag: payload.Id,
    iconUrl: payload.OwnerProfileImage ?? drawUserAvatar(displayName),
  };

  show(options);
});

const route = useRoute();
workspaceInvitationDeleted.on(async payload => {
  void queryClient.invalidateQueries(workspaceQueries.security(payload.FilesystemId));
  void queryClient.invalidateQueries({ queryKey: workspaceQueries.workspace(payload.FilesystemId) });
  if (payload.FilesystemDelete) {
    void queryClient.invalidateQueries({ queryKey: workspaceQueries.workspaces() });
  }

  const workspaceIdParameter = route.params[workspaceParameterName];
  if (payload.FilesystemDelete && workspaceIdParameter === payload.FilesystemId) {
    await router.push(toManage());
  }
});
</script>

<!-- eslint-disable vue/valid-template-root -->
<template>
</template>
