<script setup lang="ts">
import type { DialogTitleProps } from "reka-ui";
import { DialogTitle, useForwardProps } from "reka-ui";

const props = defineProps<DialogTitleProps>();
const forwarded = useForwardProps(props);
</script>

<template>
  <DialogTitle v-bind="forwarded" class="text-balance text-lg/6 font-semibold">
    <slot />
  </DialogTitle>
</template>
