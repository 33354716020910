<script setup lang="ts">
import type { ComboboxContentEmits, ComboboxContentProps } from "reka-ui";
import { ComboboxContent, useForwardPropsEmits } from "reka-ui";

const props = defineProps<ComboboxContentProps>();
const emits = defineEmits<ComboboxContentEmits>();

const forwarded = useForwardPropsEmits(props, emits);
</script>

<template>
  <ComboboxContent
    v-bind="forwarded"
    class="overflow-y-auto overflow-x-hidden sm:max-h-96"
  >
    <div role="presentation">
      <slot />
    </div>
  </ComboboxContent>
</template>
