import type { RouteLocationNormalizedLoaded, RouteLocationRaw, RouteRecordNormalized } from "vue-router";
import { removeDuplicateForwardSlashes } from "../lib";
import { resolveApiBaseUrl } from "./api";

export const redirectParamName = "redirect";
export const authParamName = "auth";
export const targetIdParamName = "targetId";

interface MakeUrlOptions {
  relative?: boolean;
  baseUrl?: string;
}

const defaultOptions: MakeUrlOptions = {
  relative: false,
  baseUrl: undefined,
};

export const wellKnownRoutes = {
  home: {
    path: "/",
    name: "home",
  },
  uploadFromSketch: {
    path: "/upload-from-sketch",
    name: "uploadfromsketch",
  },
  downloadingUpdatesProgress: {
    path: "/downloading-updates-progress",
    name: "downloadingupdatesprogress",
  },
  proxylogin: {
    path: "/proxy-login",
    name: "proxylogin",
    props: true,
  },
  login: {
    path: "/login",
    name: "login",
  },
};

function isRoute(to: RouteLocationRaw | RouteRecordNormalized | RouteLocationNormalizedLoaded): to is RouteLocationNormalizedLoaded {
  return (to as any).fullPath !== undefined;
}

export function makeUrl(bundleSuffix: string, to: RouteLocationRaw | RouteLocationNormalizedLoaded, options: MakeUrlOptions = defaultOptions) {
  const { relative, baseUrl } = options;
  const base = relative ? "/" : baseUrl || resolveApiBaseUrl();
  const path = typeof to === "string" ? to : (isRoute(to) ? to.fullPath : to.path) || to.path || "";
  const url = removeDuplicateForwardSlashes(`${base}/${bundleSuffix}/${path}`);
  return url;
}

export function makeWebUrl(to: RouteLocationRaw | RouteLocationNormalizedLoaded, options = defaultOptions) {
  const webSuffix = import.meta.env.BASE_URL || "";
  return makeUrl(webSuffix, to, options);
}
