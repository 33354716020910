import type { AxShareConfigModel } from "@/generated/models";
import { isDesktopLocal, isElectronBuild } from "@/common/environment";

export function resolveApiBaseUrl(config?: AxShareConfigModel | null): string {
  if (config) {
    return config.MatchProtocolMode ? config.AxShareHostUrl : config.AxShareHostSecureUrl;
  }

  let result: string | undefined;
  if (isDesktopLocal) {
    result = import.meta.env.VITE_APP_DESKTOP_AXSHARE_API_URL;
  } else if (isElectronBuild) {
    result = import.meta.env.VITE_APP_AXSHARE_ELECTRON_API_URL;
  } else {
    result = import.meta.env.VITE_APP_AXSHARE_API_URL;
  }

  return result || "";
}

export function resolveAccountServiceHost(config?: AxShareConfigModel | null): string {
  if (config) {
    return config.MatchProtocolMode ? config.AccountServiceUrl : config.AccountServiceSecureUrl;
  }
  return import.meta.env.VITE_APP_AXSHARE_ACCOUNT_SERVICE_URL || "";
}
