import type { RequireExactlyOne } from "type-fest";
import type { VNode } from "vue";
import type { RouteLocationRaw } from "vue-router";
import type { ToastRootProps } from "./types";
import { asError } from "@/common/lib";
import { secondsToMilliseconds } from "date-fns";
import { markRaw, ref } from "vue";

interface ToastPromiseOptions<T = any> {
  onSuccess?: (data: T) => ToastOptions | string | undefined;
  onError?: (error: Error) => ToastOptions | Error | string | undefined;
  onSettled?: (data: T | undefined, error: Error | undefined) => ToastOptions | string | undefined;
}

interface ToastBase {
  title: string;
  variant?: "primary" | "destructive";
  description?: string | (() => VNode);
};

interface ToastClickableAction {
  onClick: (event: MouseEvent) => void;
  to: RouteLocationRaw;
  text: string;
}

type ToastClickAction = RequireExactlyOne<ToastClickableAction, "onClick" | "to">;

type ToastComponentAction = () => VNode;

type ToastCustomAction = ToastClickAction | ToastComponentAction;

type WithAction = ToastBase & {
  action: ToastCustomAction;
  actionAltText: string;
};
type WithoutAction = ToastBase & {
  action?: never;
  actionAltText?: never;
};

type ToastOptions = ToastBase & (WithAction | WithoutAction);

export interface Toast {
  id: string;
  options: ToastOptions;
  props: ToastRootProps;
}

const actionTypes = {
  ADD_TOAST: "ADD_TOAST",
  DISMISS_TOAST: "DISMISS_TOAST",
  REMOVE_TOAST: "REMOVE_TOAST",
} as const;

type ActionType = typeof actionTypes;

type Action =
  | {
    type: ActionType["ADD_TOAST"];
    toast: Toast;
  }
  | {
    type: ActionType["DISMISS_TOAST"];
    toastId?: Toast["id"];
  }
  | {
    type: ActionType["REMOVE_TOAST"];
    toastId?: Toast["id"];
  };

class LimitedSizeMap<K, V> extends Map<K, V> {
  private maxSize: number;

  constructor(maxSize: number, entries?: readonly (readonly [K, V])[] | null) {
    super(entries);
    this.maxSize = maxSize;
  }

  set(key: K, value: V): this {
    if (this.size >= this.maxSize) {
      // Remove the first (oldest) element added to the map
      const firstKey = this.keys().next().value;
      if (firstKey !== undefined) {
        this.delete(firstKey);
      }
    }
    super.set(key, value);
    return this;
  }
}

const TOAST_LIMIT = 1;
const toasts = ref(new LimitedSizeMap<string, Toast>(TOAST_LIMIT));

export function useToast() {
  const TOAST_REMOVE_DELAY = secondsToMilliseconds(10);

  let count = 0;
  function genId() {
    count = (count + 1) % Number.MAX_VALUE;
    return count.toString();
  }

  const toastTimeouts = new Map<string, ReturnType<typeof setTimeout>>();

  function addToRemoveQueue(toastId: string, duration?: number) {
    if (toastTimeouts.has(toastId)) {
      return;
    };

    const removeDelay = Math.min(duration ?? TOAST_REMOVE_DELAY, TOAST_REMOVE_DELAY);
    const timeout = setTimeout(() => {
      toastTimeouts.delete(toastId);
      dispatch({
        type: actionTypes.REMOVE_TOAST,
        toastId,
      });
    }, removeDelay);

    toastTimeouts.set(toastId, timeout);
  }

  function dispatch(action: Action) {
    switch (action.type) {
      case actionTypes.ADD_TOAST:
        if (action.toast.options.action) {
          action.toast.options.action = markRaw(action.toast.options.action);
        }
        toasts.value.set(action.toast.id, action.toast);
        break;

      case actionTypes.DISMISS_TOAST: {
        const toastId = action.toastId;
        if (toastId) {
          const toast = toasts.value.get(toastId);
          addToRemoveQueue(toastId, toast?.props.duration);
        } else {
          toasts.value.forEach(toast => {
            addToRemoveQueue(toast.id);
          });
        }

        if (!toastId) {
          toasts.value.forEach(t => (t.props.open = false));
        } else {
          const toast = toasts.value.get(toastId);
          if (toast) {
            toast.props.open = false;
          }
        }

        break;
      }

      case actionTypes.REMOVE_TOAST:
        if (action.toastId === undefined) {
          toasts.value.clear();
        } else {
          toasts.value.delete(action.toastId);
        }

        break;
    }
  }

  function toast(options: ToastOptions) {
    const id = genId();

    const dismiss = () => dispatch({ type: actionTypes.DISMISS_TOAST, toastId: id });

    dispatch({
      type: actionTypes.ADD_TOAST,
      toast: {
        id,
        options,
        props: {
          open: true,
          onOpenChange: open => {
            if (!open) {
              dismiss();
            };
          },
        },
      },
    });

    return {
      id,
      dismiss,
    };
  }

  async function toastPromise<T>(promise: Promise<T>, options: ToastPromiseOptions<T> = {}) {
    try {
      const data = await promise;
      success(data);
      settled(data, undefined);
      return data;
    } catch (error) {
      errored(error);
      settled(undefined, asError(error));
      throw error;
    }

    function success(data: Awaited<T>) {
      let props = options.onSuccess?.(data);
      if (!props) return;
      if (typeof props === "string") {
        props = { title: props };
      }

      if (!props.title) return;

      toast(props);
    }

    function errored(error: unknown) {
      let props = options.onError?.(asError(error));
      if (!props) return;
      if (typeof props === "string") {
        props = { title: props };
      } else if (props instanceof Error) {
        props = { title: props.message };
      }

      if (!props.title) return;

      props.variant ??= "destructive";
      toast(props);
    }

    function settled(data: Awaited<T> | undefined, error: Error | undefined) {
      let props = options.onSettled?.(data, error);
      if (!props) return;
      if (typeof props === "string") {
        props = { title: props };
      }

      if (!props.title) return;

      toast(props);
    }
  }

  return {
    toasts,
    toast,
    toastPromise,
    dismiss: (toastId?: string) => dispatch({ type: actionTypes.DISMISS_TOAST, toastId }),
  };
}

export const forwardError: ToastPromiseOptions["onError"] = (e: Error) => {
  return e;
};
