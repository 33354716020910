<script setup lang="ts">
import { useQuery, useQueryClient } from "@tanstack/vue-query";
import Center from "ls/layouts/Center.vue";
import { queries } from "ls/state/queries";
import { InfoIcon } from "lucide-vue-next";
import { computed, watch } from "vue";
import { SimpleAlert, SimpleAlertDescription, SimpleAlertTitle } from "./simple-alert";
import SimpleButton from "./simple-button/SimpleButton.vue";
import Text from "./Text.vue";
import TextLinkEmailSupport from "./TextLinkEmailSupport.vue";

const { isFetched, isError, data } = useQuery(queries.config());
const showError = computed(() => isFetched.value && (!data.value || isError.value));

// just reload the page if the error was resolved
watch(showError, (hasError, hadError) => {
  if (!hasError && hadError) {
    window.location.reload();
  }
});

const client = useQueryClient();
function handleRetry() {
  void client.invalidateQueries(queries.config());
}
</script>

<template>
  <Center v-if="showError">
    <div class="sm:max-w-xl">
      <div class="rounded-sm bg-white shadow-xs p-6">
        <h1 class="text-lg font-semibold mb-4">
          Failed to connect to Axure Cloud
        </h1>
        <SimpleAlert variant="destructive">
          <InfoIcon class="size-4" aria-hidden="true" />
          <SimpleAlertTitle>Error</SimpleAlertTitle>
          <SimpleAlertDescription>We encountered an issue connecting to Axure Cloud</SimpleAlertDescription>
        </SimpleAlert>
        <Text class="mt-4">
          This could be due to network issues, server problems, or an incorrect configuration. Please try again or contact support at <TextLinkEmailSupport /> if the problem persists.
        </Text>
        <div class="mt-4 text-center">
          <SimpleButton size="large" @click="handleRetry">
            Retry
          </SimpleButton>
        </div>
      </div>
    </div>
  </Center>
</template>
