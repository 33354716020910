<script setup lang="ts">
import type { DialogContentEmits, DialogContentProps } from "reka-ui";
import { DialogContent, DialogPortal, useForwardPropsEmits } from "reka-ui";
import SimpleDialogOverlay from "./SimpleDialogOverlay.vue";

const props = defineProps<DialogContentProps & {
  variant?: "default" | "small" | "large";
}>();
const emits = defineEmits<DialogContentEmits>();
const forwarded = useForwardPropsEmits(props, emits);
</script>

<script lang="ts">
// from radix-vue docs to prevent closing of modal when clicking on scrollbar.
function preventCloseOnScrollbarClick(event: CustomEvent) {
  const originalEvent = event.detail.originalEvent;
  const target = originalEvent.target as HTMLElement;
  if (originalEvent.offsetX > target.clientWidth || originalEvent.offsetY > target.clientHeight) {
    event.preventDefault();
  }
}
</script>

<template>
  <DialogPortal>
    <SimpleDialogOverlay>
      <div class="fixed inset-0 w-screen overflow-y-auto pt-6 sm:pt-0">
        <div
          class="grid min-h-full grid-rows-[1fr_auto] justify-items-center sm:grid-rows-[minmax(0,15%)_auto] sm:p-4"
        >
          <DialogContent
            v-bind="forwarded"
            class="row-start-2 grid w-full min-w-0 gap-4 rounded-sm bg-white p-6 shadow-lg sm:mb-auto"
            :class="{
              'sm:max-w-sm': variant === 'small',
              'sm:max-w-lg': !variant || variant === 'default',
              'sm:max-w-5xl': variant === 'large',
            }"
            @pointer-down-outside="preventCloseOnScrollbarClick"
          >
            <slot />
          </DialogContent>
        </div>
      </div>
    </SimpleDialogOverlay>
  </DialogPortal>
</template>
