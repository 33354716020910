import { asError } from "@/common/lib";
import { RpErrorTypes } from "@/generated/models";
import { createSharedComposable } from "@vueuse/core";
import { computed, readonly, ref } from "vue";

type ErrorType = "licenseAudit" | "subInstanceLicenseAudit" | "noPermission";

function useRpAccessControlFn() {
  const errorType = ref<ErrorType>();

  async function executeCheckedEndpoint<T>(endpoint: () => Promise<T>): Promise<T> {
    try {
      return await endpoint();
    } catch (e) {
      const err = asError(e);
      const detectedType = getErrorType(err);
      if (detectedType) {
        errorType.value = detectedType;
        err.message = "";
        throw err;
      }

      throw e;
    }
  }

  function clear() {
    errorType.value = undefined;
  }

  return {
    executeCheckedEndpoint,
    clear,
    errorType: readonly(errorType),
    isError: computed(() => errorType.value !== undefined),
  };
}

function getErrorType(error: Error | undefined): ErrorType | undefined {
  if (!error?.cause || typeof error.cause !== "object" || !("ErrorType" in error.cause)) return undefined;

  if (error.cause.ErrorType === RpErrorTypes.LicenseAuditFailed) {
    return "licenseAudit";
  }
  if (error.cause.ErrorType === RpErrorTypes.SubInstanceLicenseAuditFailed) {
    return "subInstanceLicenseAudit";
  }
  if (error.cause.ErrorType === RpErrorTypes.NoPermissionToAccess) {
    return "noPermission";
  }

  return undefined;
}

export const useRpAccessControl = createSharedComposable(useRpAccessControlFn);
