<script setup lang="ts">
import type { ComboboxGroupProps } from "reka-ui";
import { ComboboxGroup, useForwardProps } from "reka-ui";

const props = defineProps<ComboboxGroupProps>();
const forwardedProps = useForwardProps(props);
</script>

<template>
  <ComboboxGroup
    v-bind="forwardedProps"
    class="overflow-hidden p-1"
  >
    <slot />
  </ComboboxGroup>
</template>
