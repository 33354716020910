<script setup lang="ts">
import { secondsToMilliseconds } from "date-fns";
import { ToastProvider, ToastRoot, ToastViewport } from "reka-ui";
import SimpleToast from "./SimpleToast.vue";
import { useToast } from "./useToast";

const { toasts } = useToast();
const toastDuration = secondsToMilliseconds(5);
// use large swipe-threshold to prevent swiping when selecting text
const swipeThreshold = 10_000;
</script>

<template>
  <ToastProvider
    :duration="toastDuration"
    :swipe-threshold="swipeThreshold"
  >
    <div class="fixed inset-x-0 bottom-20 z-10 grid items-end justify-items-center">
      <div class="absolute [&_[role=region]]:flex">
        <ToastRoot
          v-for="[id, toast] in toasts"
          :key="id"
          as-child
          v-bind="toast.props"
          :duration="toast.options.variant === 'destructive' ? 0 : undefined"
          @update:open="toast.props.onOpenChange"
        >
          <SimpleToast :toast="toast" />
        </ToastRoot>
        <ToastViewport class="flex flex-col gap-2" />
      </div>
    </div>
  </ToastProvider>
</template>
