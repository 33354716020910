<script setup lang="ts">
import type { ClickthroughUploadBatch } from "./useClickthroughUploader";
import ButtonClose from "ls/components/simple-button/ButtonClose.vue";
import SimpleButton from "ls/components/simple-button/SimpleButton.vue";
import SimpleProgress from "ls/components/simple-progress/SimpleProgress.vue";
import { useAxiosProgressStats } from "ls/composables/useAxiosUploadProgress";
import { toFileOverview } from "ls/router/builders";
import { CheckCircle2Icon, CircleAlertIcon, LoaderCircleIcon } from "lucide-vue-next";
import { computed } from "vue";
import { useBatchFiles, useClickthroughUploader } from "./useClickthroughUploader";

const props = defineProps<{
  batch: ClickthroughUploadBatch;
}>();

const { dismiss } = useClickthroughUploader();
const files = useBatchFiles(() => props.batch);

const { current, total } = useAxiosProgressStats(() => props.batch.progress);

const statusText = computed(() => {
  const status = props.batch.status;
  if (status === "pending") return "Uploading";
  if (status === "exporting") return "Exporting";
  if (status === "success") return "Complete";
  if (status === "error") return "Error";
  if (status === "canceled") return "Canceled";
  return undefined;
});

const icon = computed(() => {
  const status = props.batch.status;
  if (status === "exporting") return LoaderCircleIcon;
  if (status === "pending") return LoaderCircleIcon;
  if (status === "success") return CheckCircle2Icon;
  if (status === "error") return CircleAlertIcon;
  return undefined;
});
</script>

<template>
  <div class="rounded-sm border border-slate-200 bg-white p-3">
    <div class="flex items-center">
      <h2 class="flex items-center truncate font-semibold leading-7">
        Upload to {{ batch.shortcutName }}
      </h2>
      <ButtonClose
        class="ml-auto"
        @click="dismiss(batch.id)"
      />
    </div>
    <div class="mt-4 flex max-h-40 flex-col gap-2 overflow-auto">
      <div v-for="(file, index) in files" :key="index">
        <div class="mb-1 flex gap-4">
          <div class="min-w-0 truncate">
            {{ file.name }}
          </div>
          <div class="ml-auto">
            <span class="inline-flex items-center gap-2">
              {{ statusText }}
              <span class="inline-flex items-center overflow-hidden">
                <component
                  :is="icon"
                  v-if="icon"
                  class="inline size-4"
                  :class="{
                    'text-red-600': batch.status === 'error',
                    'text-lime-600': batch.status === 'success',
                    'animate-spin': batch.status === 'pending',
                  }"
                  aria-hidden="true"
                />
              </span>
            </span>
          </div>
        </div>
        <SimpleProgress
          :current="current"
          :total="total"
        />
      </div>
    </div>
    <div v-if="batch.error" class="mt-1">
      <p
        class="text-sm text-red-600"
      >
        {{ batch.error.message }}
      </p>
    </div>
    <div class="mt-4 flex justify-end">
      <SimpleButton :href="toFileOverview(batch.shortcut)">
        Go to overview
      </SimpleButton>
    </div>
  </div>
</template>
