import { useMutation, useQuery, useQueryClient } from "@tanstack/vue-query";
import { whenever } from "@vueuse/core";
import { produce } from "immer";
import { STALE } from "ls/queries/staleTime";
import { computed } from "vue";
import { queries as userQueries } from "../user/queries";
import { useAccountService } from "../user/useAccountService";
import { useCurrentUserInfo } from "../user/useAuth";

export function useTimezones() {
  const { getTimeZones, isAuthenticated } = useAccountService();
  const query = useQuery({
    queryKey: ["timezones"] as const,
    queryFn: ({ signal }) => {
      return getTimeZones({ signal });
    },
    enabled: isAuthenticated,
    staleTime: STALE.INFINITY,
  });

  const timezones = computed(() => {
    if (!query.data.value) return [];
    return query.data.value.Items;
  });

  return timezones;
}

export function useSetTimezone() {
  const queryClient = useQueryClient();
  const { setUserTimeZone } = useAccountService();

  return useMutation({
    mutationFn: setUserTimeZone,

    onMutate(timeZoneId) {
      queryClient.setQueryData(userQueries.info().queryKey, previous => {
        if (!previous) return;

        return produce(previous, state => {
          if (timeZoneId) {
            state.localTimeZone = timeZoneId;
          }
        });
      });
    },

    onSettled() {
      queryClient.invalidateQueries(userQueries.info());
    },
  });
}

export function useTimezoneAutoDetection() {
  const userInfo = useCurrentUserInfo();
  const timezones = useTimezones();

  const timezonesLoaded = computed(() => timezones.value.length > 0);

  const isUserTimeZoneInvalid = computed(() => {
    const tzId = userInfo.value?.localTimeZone;
    if (!tzId) return true;
    const timezoneInList = timezones.value.some(tz => tz.Id === tzId);
    return timezoneInList === false;
  });

  const autoUpdateNeeded = computed(() => {
    return userInfo.value && timezonesLoaded.value && isUserTimeZoneInvalid.value;
  });

  const { mutate } = useSetTimezone();

  whenever(autoUpdateNeeded, () => {
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    mutate(userTimeZone);
  }, {
    immediate: true,
    once: true,
  });
}
