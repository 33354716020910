<script setup lang="ts">
import type { TooltipProviderProps } from "reka-ui";
import { TooltipProvider, useForwardProps } from "reka-ui";

const props = defineProps<TooltipProviderProps>();
const forwarded = useForwardProps(props);
</script>

<template>
  <TooltipProvider
    v-bind="forwarded"
    :delay-duration="500"
    :skip-delay-duration="300"
  >
    <slot />
  </TooltipProvider>
</template>
