<template>
  <div
    class="flex min-h-[60px] items-center bg-slate-900 px-8 py-4 gap-4"
  >
    <p class="text-slate-100">
      <slot />
    </p>
    <slot name="controls" />
  </div>
</template>
