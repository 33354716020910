import type { RefOrGetter } from "ls/common/types";
import Axios from "axios";
import { setupServer } from "ls/api/common/server";
import { onUnmounted, shallowRef, toValue, watch } from "vue";
import { authToken } from "./authTokenStorage";

function create(baseUrl?: string) {
  const instance = Axios.create({
    baseURL: baseUrl,
    withCredentials: true,
  });

  setupServer(instance);

  instance.interceptors.request.use(request => {
    const token = authToken.get();
    if (token) {
      request.headers.set("Authorization", token, true);
    }
    return request;
  });

  return instance;
}

export function useAxiosInstance(baseUrl: RefOrGetter<string | undefined>) {
  const axiosInstance = shallowRef(create(toValue(baseUrl)));
  watch(baseUrl, url => {
    if (axiosInstance.value) {
      axiosInstance.value.defaults.baseURL = url;
    }
  });

  function dispose() {
    if (!axiosInstance.value) return;
    axiosInstance.value.interceptors.request.clear();
    axiosInstance.value.interceptors.response.clear();
  }

  onUnmounted(() => {
    dispose();
  });

  return axiosInstance;
}
