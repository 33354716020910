<script setup lang="ts">
defineProps<{
  variant?: AlertProps["variant"];
}>();
</script>

<script lang="ts">
import type { VariantProps } from "class-variance-authority";
import { cva } from "class-variance-authority";

export type AlertProps = VariantProps<typeof alert>;

const alert = cva([
  "relative w-full rounded-sm border p-4 [&>svg+div]:translate-y-[-3px] [&>svg]:absolute [&>svg]:left-4 [&>svg]:top-4 [&>svg~*]:pl-7",
], {
  variants: {
    variant: {
      default: "",
      warning: "border-yellow-400 bg-yellow-50 text-yellow-700",
      destructive: "border-red-400 bg-red-50 text-red-700",
    },
  },
  defaultVariants: {
    variant: "default",
  },
});
</script>

<template>
  <div :class="alert({ variant })">
    <slot />
  </div>
</template>
