<script setup lang="ts">
import type { ComboboxLabelProps } from "reka-ui";
import { ComboboxLabel, useForwardProps } from "reka-ui";

const props = defineProps<ComboboxLabelProps>();
const forwardedProps = useForwardProps(props);
</script>

<template>
  <ComboboxLabel
    v-bind="forwardedProps"
    class="my-1 px-2 text-xs font-medium text-slate-500"
  >
    <slot />
  </ComboboxLabel>
</template>
