<script setup lang="ts">
import type { FilesystemJs, SearchFolderRowJs, ShortcutJs } from "@/generated/models";
import { CommandEmpty, CommandGroup, CommandLoading } from "ls/components/command";
import { useCommandContext } from "ls/components/command/context";
import { toFileOverview, toWorkspace } from "ls/router/builders";
import { FolderIcon } from "lucide-vue-next";
import { computed } from "vue";
import { useRouter } from "vue-router";
import FileIcon from "../files/FileIcon.vue";
import WorkspaceIcon from "../workspaces/WorkspaceIcon.vue";
import CommandShortcutEnter from "./CommandShortcutEnter.vue";
import SearchItem from "./SearchItem.vue";
import { useSearch } from "./useSearch";

const emit = defineEmits<{
  select: [];
}>();

const { searchTerm } = useCommandContext();
const { workspaces, folders, files, isPending } = useSearch(computed(() => searchTerm.value ?? ""), { limit: 10 });

const noResults = computed(() => {
  const totalWorkspaces = workspaces.value?.length;
  const totalFolders = folders.value?.length;
  const totalFiles = files.value?.length;
  return totalWorkspaces === 0 && totalFolders === 0 && totalFiles === 0;
});

const router = useRouter();
async function goToFile(file: ShortcutJs) {
  emit("select");
  await router.push(toFileOverview(file));
}

async function goToWorkspace(workspace: FilesystemJs) {
  emit("select");
  await router.push(toWorkspace(workspace));
}

async function goToFolder(folder: SearchFolderRowJs) {
  emit("select");
  await router.push(toWorkspace(folder.FilesystemId, { folderId: folder.Folder.FolderId }));
}
</script>

<template>
  <CommandLoading v-if="isPending" />
  <CommandGroup heading="Files">
    <SearchItem
      v-for="file in files"
      :key="file.Shortcut.Shortcut"
      :value="file"
      :name="file.Shortcut.Name"
      :path="file.Path"
      :href="toFileOverview(file.Shortcut)"
      @select="goToFile(file.Shortcut)"
    >
      <template #icon>
        <FileIcon class="size-4 shrink-0" :file="file.Shortcut" />
      </template>
      <CommandShortcutEnter />
    </SearchItem>
  </CommandGroup>
  <CommandGroup heading="Workspaces and folders">
    <SearchItem
      v-for="workspace in workspaces"
      :key="workspace.Filesystem.FilesystemId"
      :value="workspace"
      :name="workspace.Filesystem.DisplayName"
      :path="workspace.Path"
      :href="toWorkspace(workspace.Filesystem)"
      @select="goToWorkspace(workspace.Filesystem)"
    >
      <template #icon>
        <WorkspaceIcon
          class="size-4 shrink-0"
          aria-hidden="true"
          :workspace="workspace.Filesystem"
        />
      </template>
      <CommandShortcutEnter />
    </SearchItem>
    <SearchItem
      v-for="folder in folders"
      :key="folder.Folder.FolderId"
      :value="folder"
      :name="folder.Folder.FolderName"
      :path="folder.Path"
      :href="toWorkspace(folder.FilesystemId, { folderId: folder.Folder.FolderId })"
      @select="goToFolder(folder)"
    >
      <template #icon>
        <FolderIcon
          class="size-4 shrink-0"
          aria-hidden="true"
        />
      </template>
      <CommandShortcutEnter />
    </SearchItem>
  </CommandGroup>

  <CommandEmpty v-if="noResults">
    Nothing to show here
  </CommandEmpty>
</template>
