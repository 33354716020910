import type {
  FilesystemJs,
  FolderRowJs,
  InvitationJs,
  SharedFilesystemMappingJs,
  ShortcutJs,
  ShortcutNotificationStateJs,
  ShortcutType,
  SsoResponse,
} from "@/generated/models";

export enum FilesystemNodeType {
  Root = "root",
  Workspace = "workspace",
  Folder = "folder",
  Shortcut = "shortcut",
  Invitation = "invitation",
  Recents = "recents",
  Search = "search",
  Organization = "organization",
}

// eslint-disable-next-line no-restricted-syntax
const enum ShortcutStateEnum {
  Empty = 0,
  GenerationFailed = 1,
  Generating = 2,
  Deleted = 3,
  Ok = 4,
}

// eslint-disable-next-line no-restricted-syntax
export const enum GenerationType {
  Legacy = 0,
  BundleGen = 1,
  SinglePage = 2,
}

export type ShortcutStateName = keyof typeof ShortcutStateEnum;
export type ShortcutStateNames = { readonly [P in ShortcutStateName]: P };

export const ShortcutState: ShortcutStateNames = {
  Empty: "Empty",
  GenerationFailed: "GenerationFailed",
  Generating: "Generating",
  Deleted: "Deleted",
  Ok: "Ok",
};

export interface FilesystemNodeRoot extends FilesystemNode {
  readonly type: FilesystemNodeType.Root;
}

export interface FilesystemNodeRecents extends FilesystemNode {
  readonly type: FilesystemNodeType.Recents;
}

export interface FilesystemNodeOrganization extends FilesystemNode {
  readonly type: FilesystemNodeType.Organization;
}

export interface FilesystemNodeSearch extends FilesystemNode {
  readonly type: FilesystemNodeType.Search;
}

export interface FilesystemNodeWorkspace extends FilesystemNode {
  readonly type: FilesystemNodeType.Workspace;
  rootFolderId: string;
  isDefault: boolean;
  listingNode: FilesystemJs | undefined;
  sharedWith: SharedFilesystemMappingJs[];
  invited: SharedFilesystemMappingJs[];
  users: SharedFilesystemMappingJs[];
  viewOnly: boolean;
  isOwner: boolean;
  ownerId: string;
  owner?: SsoResponse;
  ownerEmail: string;
  userOnly: boolean;
  httpsOnly: boolean;
  isArchived: boolean;
  isFavorite: boolean;
  subInstanceId: string;
  defaultMappingType: number;
  organizationName: string;
  isFilesystemToJoin?: boolean;
}

export interface FilesystemNodeInvitation extends FilesystemNode {
  readonly type: FilesystemNodeType.Invitation;
  owner: SsoResponse;
  sender: SsoResponse;
  message: string;
  listingNode: InvitationJs | undefined;
  subInstanceId: string;
  isArchived: boolean;
}

export interface FilesystemNodeFolder extends FilesystemNode {
  readonly type: FilesystemNodeType.Folder;
  listingNode: FolderRowJs | undefined;
  viewOnly: boolean;
  updated?: Date;
  updatedBy?: string;
}

export interface FilesystemNodeShortcut extends FilesystemNode {
  readonly type: FilesystemNodeType.Shortcut;
  listingNode: ShortcutJs | undefined;
  shortcutType: ShortcutType;
  prototypeUrl: string;
  shortcut: string;
  isExpoProject: boolean;
  viewOnly: boolean;
  updated?: Date;
  updatedBy?: string;
  generated?: Date;
  shortcutState: ShortcutStateName;
  feedbackEnabled: boolean;
  isUserOnly: boolean;
  hasPassword: boolean;
  isUserOnlyWorkspace: boolean;
  notificationState: ShortcutNotificationStateJs;
  accessCode: string;
}

export type FilesystemNodeTypes =
  | FilesystemNodeRoot
  | FilesystemNodeFolder
  | FilesystemNodeShortcut
  | FilesystemNodeWorkspace
  | FilesystemNodeInvitation
  | FilesystemNodeRecents
  | FilesystemNodeSearch
  | FilesystemNodeOrganization;

export interface FilesystemNode {
  type: FilesystemNodeType;
  id: string;
  name: string;
  parent: FilesystemNodeTypes | undefined;
  listingNode: any;
  created: Date;
  totalItems: number;
}

// eslint-disable-next-line no-restricted-syntax
const enum MentionSourceTypeEnum {
  Issue = 0,
  IssueComment = 1,
  PublishNote = 2,
  DiskoDocumentNode = 3,
  DiskoDiagramObject = 4,
}

export type MentionTypeName = keyof typeof MentionSourceTypeEnum;
export type MentionTypeNames = { readonly [P in MentionTypeName]: P };

export const MentionType: MentionTypeNames = {
  Issue: "Issue",
  IssueComment: "IssueComment",
  PublishNote: "PublishNote",
  DiskoDocumentNode: "DiskoDocumentNode",
  DiskoDiagramObject: "DiskoDiagramObject",
};
