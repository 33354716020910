<script setup lang="ts">
import type { ComboboxGroupProps } from "reka-ui";
import { computed } from "vue";
import { SimpleComboboxGroup, SimpleComboboxLabel } from "../simple-combobox";

const props = defineProps<ComboboxGroupProps & {
  heading?: string;
}>();

const delegatedProps = computed(() => {
  const { heading: _, ...delegated } = props;

  return delegated;
});
</script>

<template>
  <SimpleComboboxGroup
    v-bind="delegatedProps"
  >
    <SimpleComboboxLabel
      v-if="heading"
    >
      {{ heading }}
    </SimpleComboboxLabel>
    <slot />
  </SimpleComboboxGroup>
</template>
