import { isElectron } from "@/common/environment";
import { isHttp } from "@/common/lib";
import { useLocalStorage, usePermission, useSupported } from "@vueuse/core";
import { computed, ref } from "vue";

export interface DesktopNotificationOptions {
  title: string;
  body: string;
  tag?: string;
  iconUrl?: string;
  onclick?: () => void;
}

export function useDesktopNotifications() {
  // web notifications aren't allowed in insecure contexts
  const notSupportedOnHttp = isHttp(window.location.href);

  const notificationsEnabled = useLocalStorage("desktop-notifications-enabled", () => {
    // electron always has "granted" permission
    // for electron if notifications option is not set we consider it turned on
    if (isElectron) {
      return true;
    }

    // for browser if notifications option is not set we consider it turned off
    // browser will ask permission when option is turned on manually
    return false;
  });

  const permission = usePermission("notifications");

  // replaces useWebNotification from vueuse until empty notifications are fixed. see: https://github.com/vueuse/vueuse/issues/4017
  const isSupported = useSupported(() => {
    if (!window || !("Notification" in window)) {
      return false;
    }
    if (Notification.permission === "granted") {
      return true;
    }

    // https://stackoverflow.com/questions/29774836/failed-to-construct-notification-illegal-constructor/29895431
    // https://issues.chromium.org/issues/40415865
    try {
      const notification = new Notification("");
      notification.onshow = () => {
        notification.close();
      };
    } catch (e) {
      // Android Chrome: Uncaught TypeError: Failed to construct 'Notification': Illegal constructor. Use ServiceWorkerRegistration.showNotification() instead.
      // @ts-expect-error catch TypeError
      if (e.name === "TypeError") {
        return false;
      }
    }

    return true;
  });

  const permissionGranted = ref(isSupported.value && "permission" in Notification && Notification.permission === "granted");
  const ensurePermissions = async () => {
    if (!isSupported.value) {
      return;
    }

    if (!permissionGranted.value && Notification.permission !== "denied") {
      const result = await Notification.requestPermission();
      if (result === "granted") {
        permissionGranted.value = true;
      }
    }

    return permissionGranted.value;
  };

  async function toggle(enable: boolean) {
    if (enable) {
      await ensurePermissions();

      if (permission.value === "granted") {
        notificationsEnabled.value = true;
      } else if (permission.value === "denied") {
        notificationsEnabled.value = false;
      }
    } else {
      notificationsEnabled.value = false;
    }
  }

  const isDenied = computed(() => permission.value === "denied");

  const enabled = computed(() => {
    if (!isSupported.value) return false;
    if (permission.value === "denied" || permission.value === "prompt") return false;
    return notificationsEnabled.value;
  });

  const canBeEnabled = computed(() => {
    if (permission.value === "denied") return false;
    if (!isSupported.value) return false;
    return true;
  });

  function show(options: DesktopNotificationOptions) {
    if (!enabled.value) return;
    try {
      const notification = new Notification(options.title ?? "Axure", {
        body: options.body ?? "",
        tag: options.tag,
        icon: options.iconUrl,
      });

      const onclick = options.onclick;
      if (onclick) {
        notification.onclick = () => {
          // google chrome notifications are not closed by click on MacOS
          // so it should be closed manually
          notification.close();
          onclick();
        };
      }
    } catch (e) {
      console.error("Failed to display desktop notification", e);
    }
  }

  return {
    enabled,
    toggle,
    canBeEnabled,
    notSupportedOnHttp,
    isSupported,
    isDenied,
    show,
  };
}
