import type { RefOrGetter } from "ls/common/types";
import UFuzzy from "@leeoniya/ufuzzy";
import { computed, toValue } from "vue";

export function useFuzzySearch<T>(
  items: RefOrGetter<T[]>,
  getter: (item: T) => string,
  searchTerm: RefOrGetter<string>,
  options: { limit?: number } = {},
) {
  const uf = new UFuzzy({
    unicode: true,
    interSplit: "\\p{Punctuation}+",
    intraSplit: "\\p{Ll}\\p{Lu}",
    intraBound: "\\p{L}\\d|\\d\\p{L}|\\p{Ll}\\p{Lu}",
    intraChars: "[\\p{L}\\d']",
    intraContr: "'\\p{L}{1,2}\\b",
    intraMode: 1,
    intraIns: 1,
    intraSub: 1,
    intraTrn: 1,
    intraDel: 1,
  });

  const haystack = computed(() => toValue(items).map(getter));
  const idx = computed(() => {
    const needle = toValue(searchTerm);
    if (!needle) return [];

    const s = uf.search(haystack.value, needle);
    return s[0] ?? [];
  });

  const { limit } = options;
  const indexesLimited = computed(() => {
    if (limit !== undefined) return idx.value.slice(0, 25);
    return idx.value;
  });

  return computed(() => {
    const itemsValue = toValue(items);
    return indexesLimited.value.map(i => itemsValue[i]);
  });
}
