import type { DiskoEnabledJs, ShortcutCloneJs, ShortcutCreateJs } from "@/generated/models";
import type { DiskoType } from "ls/features/files/utils";
import type { RequestOptions } from "../common/server";
import type { IdOrFilesystemId } from "./types";
import { EmptyGuid } from "@/common/lib";
import { objectToFormData } from "@/services/utils/formData";
import { server } from "./server";

export type CreateModel = IdOrFilesystemId & { Name?: string; Type: DiskoType };

export async function create(model: CreateModel) {
  const formData = objectToFormData(model);
  const response = await server.post("disko/create", formData);
  return response.data as ShortcutCreateJs;
}

export const OnPremSubInstanceGuid = "00de3840-a431-482e-bef4-573e2f1297d6";
export async function enabledForOrganization(options: { organizationId: string }, { signal }: RequestOptions = {}) {
  if (!options.organizationId || options.organizationId === OnPremSubInstanceGuid) {
    return { enabled: false, simsEnabled: false } satisfies DiskoEnabledJs;
  }

  // A9-5533 Ensuring permission check returns true for private workspace in Public Cloud
  if (options.organizationId === EmptyGuid) {
    return { enabled: true, simsEnabled: false } satisfies DiskoEnabledJs;
  }

  const params = {
    organizationId: options.organizationId,
  };
  const response = await server.get("disko/enabledForOrganization", { params, signal });
  return response.data as DiskoEnabledJs;
}

export async function cloneDiskoShortcut({ shortcut, recaptchaToken }: { shortcut: string; recaptchaToken?: string }) {
  const formData = objectToFormData({ shortcut, recaptchaToken });
  const response = await server.post(`api/disko/projects/${shortcut}/clone`, formData);
  return response.data as ShortcutCloneJs;
}
