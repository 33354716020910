export function delay(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export async function retryUntilTruthy<T>(
  fn: () => T | Promise<T>,
  maxRetries?: number,
  retryTimeout?: number,
  timeout?: number,
) {
  const infinite = true;
  let shouldCancel = false;
  const cancelTimeout = timeout
    ? setInterval(() => {
        shouldCancel = true;
      }, timeout)
    : undefined;
  // eslint-disable-next-line no-unmodified-loop-condition
  while (infinite) {
    if (shouldCancel) return;

    const result = await fn();
    if (result) {
      if (cancelTimeout) {
        clearInterval(cancelTimeout);
      }
      return result;
    }
    if (maxRetries) {
      if (--maxRetries <= 0) {
        if (cancelTimeout) {
          clearInterval(cancelTimeout);
        }
        return result;
      }
    }

    await delay(retryTimeout || 0);
  }
}
