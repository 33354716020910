import type { ListJs } from "@/common/models";
import type { ItemType, NotificationJs, NotificationMethod, NotificationMuteItemJs, NotificationSettingJs, ResponseObject } from "@/generated/models";
import type { EntryType } from "ls/features/settings/types";
import type { RequestOptions } from "../common/server";
import { objectToFormData } from "@/services/utils/formData";
import { server } from "./server";

export async function getNotificationSettings({ signal }: RequestOptions = {}) {
  const response = await server.get("notification/getNotificationSettings", {
    signal,
  });
  return response.data as ListJs<NotificationSettingJs>;
}

export async function saveNotificationSettings(model: NotificationSettingJs) {
  const formData = objectToFormData(model);
  const response = await server.post("notification/saveNotificationSettings", formData);
  return response.data as NotificationSettingJs;
}

export async function getUserNotifications({ signal }: RequestOptions = {}) {
  const response = await server.get("notification/getUserSubscriptions", {
    signal,
  });
  return response.data as ListJs<NotificationJs>;
}

export interface AddNotificationModel {
  subscriptionRate: "Instant";
  targetId?: string;
  targetType: EntryType;
  isSlack: boolean;
  msTeamsUrl?: string;
  isMsTeams?: boolean;
}

export async function addNotification(model: AddNotificationModel) {
  const formData = objectToFormData(model);

  const response = await server.post("notification/addsubscription", formData);

  if (model.isSlack) {
    const responseObject = response.data as ResponseObject;
    if (responseObject.redirecturl) {
      window.location.href = responseObject.redirecturl;
      return;
    }
  }
  return response.data as NotificationJs;
}

export interface EditNotificationModel {
  notificationId: string;
  newTargetId?: string;
  newTargetType: EntryType;
  newSubRate: "Instant";
  newMsTeamsUrl?: string;
}
export async function editNotification(model: EditNotificationModel) {
  const formData = objectToFormData(model);
  const response = await server.post("notification/editSubscription", formData);
  return response.data as NotificationJs;
}

export async function getVisitorNotifications({ signal }: RequestOptions = {}) {
  const response = await server.get("notification/getUserVisitorSubscriptions", {
    signal,
  });
  return response.data as ListJs<NotificationJs>;
}

export async function deleteNotification(notificationId: string) {
  const formData = objectToFormData({ notificationId });

  const response = await server.post("notification/deleteSubscription", formData);
  return response.data as NotificationJs;
}

export async function getMuteNotifications({ signal }: RequestOptions = {}) {
  const response = await server.get("notification/getNotificationMuteItems", {
    signal,
  });
  return response.data as ListJs<NotificationMuteItemJs>;
}

export interface NotificationMuteItemModel {
  notificationType: NotificationMethod;
  targetId: string;
  targetType: ItemType;
}
export async function addMuteNotification(model: NotificationMuteItemModel) {
  const formData = objectToFormData(model);
  const response = await server.post("notification/addNotificationMuteItem", formData);
  return response.data as NotificationMuteItemJs;
}

export async function deleteMuteNotification(notificationMuteItemId: string) {
  const formData = objectToFormData({ notificationMuteItemId });
  const response = await server.post("notification/deleteNotificationMuteItem", formData);
  return response.data as NotificationJs;
}

export async function changeSlackChannel(notificationId: string) {
  const formData = objectToFormData({ notificationId });
  const response = await server.post("notification/changeSlackChannel", formData);

  const responseObject = response.data as ResponseObject;

  if (responseObject.redirecturl) {
    window.location.href = responseObject.redirecturl;
    return;
  }

  return responseObject;
}
